<!-- 库存明细 -->

<template>
  <div class="definiteDetail">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="codeValue" placeholder="搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="productNameValue" placeholder="搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-select v-model="typeValue" placeholder="变动类型" @change="handleSelectType" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <!-- <el-table-column min-width="120" show-overflow-tooltip prop="productNum" label="商品编号"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="categoryName" label="商品类目"></el-table-column>
          <el-table-column min-width="130" show-overflow-tooltip prop="orderNum" label="单号"></el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip prop="inventoryType" label="变动类型">
            <template slot-scope="scope">
              <span>{{ scope.row.inventoryType == 1 ? '进货' : scope.row.inventoryType == 2 ? '销售' : '盘存', }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="130" show-overflow-tooltip prop="beforeCount" label="变动前数量"></el-table-column>
          <el-table-column min-width="130" show-overflow-tooltip prop="changeCount" label="变动数量"></el-table-column>
          <el-table-column min-width="130" show-overflow-tooltip prop="afterCount" label="库存数量"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="createdTime" label="时间"></el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      options: [{
        value: '1',
        label: '进货'
      }, {
        value: '2',
        label: '销售'
      },
      {
        value: '3',
        label: '盘存'
      },],
      typeValue: '', // 选择类型
      codeValue: '', // 搜索商品编号
      productNameValue: '', // 搜索商品名称
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getInventoryDetailsList();
  },
  methods: {
    // 获取列表
    getInventoryDetailsList() {
      this.loading = true;
      let params = {
        inventoryType: this.typeValue || null, // 库存类型
        // productNum: this.codeValue || null, // 搜索商品编号 
        barCode: this.codeValue || null, // 搜索商品条形码 
        productName: this.productNameValue || null, // 搜索商品名称
        supplierId: this.$store.state.userInfo.supplierId, //供应商id
        shopId: this.$store.state.userInfo.shopId, //shopId
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$axios.get(this.$api.getInventoryDetailsList, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getInventoryDetailsList();
    },
    // 选择类型
    handleSelectType(value) {
      this.clearData();
      this.typeValue = value;
      this.getInventoryDetailsList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getInventoryDetailsList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getInventoryDetailsList();
    },
  }

}



</script>

<style scoped lang="scss">
.definiteDetail {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 180px;
      margin-left: 10px;
    }

    .el-select {
      margin-left: 10px;
    }
  }
}
</style>
